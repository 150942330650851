<template>
<Modal
  class="manager-backer-modal"
  :expanded="false"
  :submitable="false"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div v-if="pledge" class="backer-modal-content">
      <div class="top-section">
        <div class="user-info">
          <div class="user-name">
            {{ pledge.user_name }}
          </div>
          <div class="user-email">
            {{ pledge.user_email }}
          </div>
          <div class="pledge-id">
            {{ `#${pledge.id}` }}
          </div>
        </div>
        <div class="top-buttons">
          <div class="cancel">
            {{ $t('manager.backers.pledge.cancel') }}
          </div>
          <div class="refund" @click="refundOpen = !refundOpen">
            {{ $t('manager.backers.pledge.refund') }}
          </div>
          <div class="message">
            <a :href="`mailto:${pledge.user_email}`" target="_blank">
              {{ $t('manager.backers.pledge.message') }}
              <img
                :src="require('@/static/img/icons/ic_mail.png')"
                class="mail-icon"
              >
            </a>
          </div>
        </div>
      </div>
      <div v-if="refundOpen" class="backer-refund-wrap">
        <div class="backer-refund-left">
          <Radio
            v-model="refundType"
            radioValue="full"
          >
            {{ $t('manager.backers.pledge.full') }} {{ `(${pledge.formattedTotal})` }}
          </Radio>
          <div class="backer-refund-partial">
            <Radio
              v-model="refundType"
              radioValue="partial"
            />
            <TitledInput
              v-model="dataValue.partial_refund_amount"
              :placeholder="$t('manager.backers.pledge.partial')"
              type="number"
              :errorMessage="errors.partial_refund_amount"
              @input="handleFormInput('partial_refund_amount', $event)"
              @click.native="refundType = 'partial'"
            />
          </div>
        </div>
        <div class="backer-refund-right">
          <div class="backer-refund-button" :class="{ disabled: !!errors.partial_refund_amount }">
            {{ $t('manager.backers.pledge.confirm') }}
          </div>
        </div>
      </div>
      <hr>
      <div class="pledge-info">
        <div class="pledge-info-item">
          <div>{{ $t('manager.backers.status') }}</div>

          <FilterMenu
            v-model="statusData"
            :radioMode="true"
            :title="$t('manager.backers.status')"
            :loading="shippingStatusLoading"
            @input="updateShipping"
          >
            <template #title>
              <div :class="`info-right info-shipping ${pledge.shipping_status}`">
                <span>{{ $t(`manager.backers.status_filters.${pledge.shipping_status}`) }}</span>
                <div class="chevron-wrap">
                  <div class="chevron" />
                </div>
              </div>
            </template>
          </FilterMenu>
        </div>
        <div class="pledge-info-item">
          <div>{{ $t('manager.backers.pledge.date') }}</div>
          <div class="info-right">
            {{ pledge.created }}
          </div>
        </div>
        <div class="pledge-info-item">
          <div>{{ $t('manager.backers.pledge.payment') }}</div>
          <div class="info-right">
            {{ pledge.payment_status }}
          </div>
        </div>
        <div v-if="needsShipping" class="pledge-info-item">
          <div>{{ $t('shipping_address') }}</div>
          <div class="info-right shipping-address">
            {{ shipping.line1 }}<br>{{ shipping.line2 }}
          </div>
        </div>
      </div>
      <hr>
      <PledgeRewards
        :pledge="pledge"
        :projectActive="false"
        :currency="project.currency"
      />
    </div>
    <Spinner v-else :size="40" class="backer-modal-spinner" />
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';
import PledgeRewards from '@/components/project/PledgeRewards.vue';
import FilterMenu from '@/components/manager/FilterMenu.vue';
import Radio from '@/components/widget/Radio.vue';
import Spinner from '@/components/widget/Spinner.vue';
import TitledInput from '@/components/widget/TitledInput.vue';
import { getProjectPledge } from '@/utils/api';
import { formatPledge, formatShipping, menuFromTranslation } from '@/utils/objectUtils';
import formMixin from '@/mixins/Form';

export default {
  name: 'manager-backer-modal',
  components: {
    Modal,
    PledgeRewards,
    FilterMenu,
    Radio,
    Spinner,
    TitledInput,
  },
  mixins: [formMixin],
  props: {
    pledgeId: {
      type: Number,
      required: true,
    },
    pledgeIndex: {
      type: Number,
      required: true,
    },
    shippingStatusLoading: Boolean,
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      pledge: null,
      statusData: {},
      refundOpen: false,
      refundType: 'full',
      // Form.js data
      dataValue: {
        partial_refund_amount: null,
      },
      dataFormName: 'refund',
      rules: {
        partial_refund_amount: {
          required: false,
          validator: val => (val > 0) && ((val * 100) <= this.pledge.total),
        },
      },
    };
  },
  computed: {
    shipping() {
      return formatShipping(this.pledge.shipping_address);
    },
    needsShipping() {
      return Object.values(this.pledge.products).some(product => product.needsShipping);
    },
  },
  methods: {
    async getPledge() {
      const { pledgeId } = this;
      const projectId = this.project.id;
      const { data: { pledge } } = await getProjectPledge(projectId, pledgeId);
      this.pledge = formatPledge(pledge);
      this.statusData = menuFromTranslation(this.$t('manager.backers.status_filters')).map(d => ({
        ...d,
        checked: d.key === pledge.shipping_status,
      }));
    },
    updateShipping(data) {
      this.statusData = data;
      this.$emit('update-shipping', data);
    },
  },
  mounted() {
    this.getPledge();
  },
};
</script>

<style lang="scss">
@import 'widgets';

.backer-modal-spinner {
  margin: 24px 0 48px;
}
.backer-modal-content {
  .modal-inner {
    margin-top: 80px;
    max-width: 680px;
    padding-bottom: 160px;
  }
  .top-section {
    display: flex;
    text-align: left;
    justify-content: space-between;
    .user-info {
      @include h5;
      margin-right: 16px;
      .user-name {
        @include h3;
      }
      .pledge-id {
        color: $grey50;
      }
    }
    .top-buttons {
      display: flex;
      margin-top: 16px;
      > div {
        @include button_small_text;
        display: flex;
        color: $main-blue;
        align-items: flex-end;
        height: 32px;
        &:not(:last-child) {
          margin-right: 16px;
        }
      }
      .cancel, .refund {
        cursor: pointer;
      }
      .cancel {
        color: $main-red;
      }
      .message {
        > a {
          color: $main-blue;
        }
        img {
          width: 20px;
          height: 16px;
          position: relative;
          bottom: -3px;
          margin-left: 4px;
        }
      }
    }
  }
  .backer-refund-wrap {
    background-color: $bg-grey-light;
    display: flex;
    padding: 16px 24px 8px 8px;
    .backer-refund-left {
      .p-radio {
        @include h5;
        font-size: 16px;
        height: 48px;
      }
      .backer-refund-partial {
        display: flex;
        align-items: center;
        input {
          background-color: white;
          width: 150px;
        }
      }
    }
    .backer-refund-right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .backer-refund-button {
        @include button($main-blue, small, secondary);
        width: 150px;
        padding: 16px 0 12px;
      }
    }
  }
  .reward-label {
    color: grey;
  }
  hr {
    margin: 24px 0 16px;
  }
  .pledge-info {
    @include h5;
    text-align: left;
    .pledge-info-item {
      margin: 8px 0;
      display: flex;
      @include shipping-title;
      > div:first-child {
        min-width: 160px;
      }
      .info-shipping {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
  }
  .pledge-rewards-wrap .pledge-item .item-image {
    width: 42px;
    height: 42px;
  }
}
</style>
