<template>
<div class="insights-content insights-referrers">
  <div class="referrers-main">
    <div class="insights-header">
      {{ $t('manager.insights.referrers.header') }}
      <div class="learn-more">
        {{ $t('learn_more') }}
      </div>
    </div>
    <InsightsList
      :items="referrerData"
      :listHeaders="referrerHeaders"
      tableClass="referrers-table"
    />
  </div>
  <div class="referrers-tags">
    <div class="insights-header tags-header">
      {{ $t('manager.insights.referrers.tags') }}
      <div class="learn-more">
        {{ $t('learn_more') }}
      </div>
    </div>
    <div class="tags-info">
      {{ $t('manager.insights.referrers.tags_info') }}
    </div>
    <ErrorMessage
      v-if="generateError"
      :errorMessage="generateError"
    />
    <div class="tags-row-header">
      <div>
        <input
          :placeholder="$t('manager.insights.referrers.tag_name')"
          :value="link"
          @input="handleInput"
        >
      </div>
      <div class="tags-copy" @click="generateLink">
        {{ $t('manager.insights.referrers.generate') }}
      </div>
    </div>
    <div class="tags-list">
      <div
        v-for="(item, idx) in links"
        :key="idx"
        class="tags-row"
      >
        <div class="tags-edit-wrap">
          <component
            :is="item.editing ? 'input' : 'div'"
            :value="item.newName"
            @input="handleEditInput(item, $event.target.value)"
            @keyup.enter="updateLink(item)"
          >
            {{ item.name }}
          </component>
          <div v-if="item.editing" class="accept-icon">
            <img
              :src="require('../../static/img/icons/ic_check.png')"
              @click="updateLink(item)"
            >
          </div>
          <Edit
            v-else
            class="edit-icon"
            @click="item.editing = true"
          />
        </div>
        <CopyButton
          :text="$t('copy_link')"
          :copyText="copyUrl(item.link)"
          styleClass="tags-copy"
        />
      </div>
      <div v-if="!links.length" class="tags-empty">
        {{ $t('manager.insights.referrers.no_tags') }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import InsightsList from '@/components/manager/InsightsList.vue';
import CopyButton from '@/components/widget/CopyButton.vue';
import Edit from '@/components/svg/Edit.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import { fieldErrorKey } from '@/utils/apiError';
import { validateLength } from '@/utils/stringUtils';
import { arraySum } from '@/utils';
import {
  getReferralLinks,
  createReferralLink,
  updateReferralLink,
  deleteReferralLink,
} from '@/utils/api';
import { siteUrl } from '@/utils/config';

export default {
  name: 'insights-referrers',
  components: {
    ErrorMessage,
    InsightsList,
    CopyButton,
    Edit,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    referrers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      link: '',
      links: [],
      generateError: null,
      referrerHeaders: [
        {
          titleKey: 'manager.insights.referrers.referrer',
          dataKey: 'referrer',
        },
        {
          titleKey: 'manager.insights.referrers.type',
          dataKey: 'category',
        },
        {
          titleKey: 'manager.insights.pledges',
          dataKey: 'backers',
        },
        {
          titleKey: 'manager.insights.percent_pledged',
          dataKey: 'percentage',
          sortKey: 'percentageSort',
        },
        {
          titleKey: 'manager.insights.amount_pledged',
          dataKey: 'amount_pledged',
          sortKey: 'amountPledgedSort',
        },
      ],
    };
  },
  computed: {
    referrerData() {
      const totalPledgeAmount = arraySum(this.referrers, referrer => referrer.pledge_amount);
      return this.referrers.map((referrer) => {
        const percentageSort = ((referrer.pledge_amount / (totalPledgeAmount || 1)) * 100);
        const percentage = `${percentageSort.toFixed(2)}%`;
        const amountPledgedSort = referrer.pledge_amount;
        return {
          referrer: referrer.name,
          category: referrer.type.toLowerCase(),
          backers: referrer.pledges,
          percentage,
          percentageSort,
          amount_pledged: this.$options.filters.from_cents(amountPledgedSort),
          amountPledgedSort,
        };
      });
    },
  },
  methods: {
    getTags(referrerData) {
      return referrerData.filter(item => item.category === 'Custom' || item.category === 'External');
    },
    copyUrl(link) {
      return `${siteUrl}/project/${this.project.vanity_url}/?ref=${link}`;
    },
    handleInput(e) {
      if(validateLength(e.target.value, { minLength: 1, maxLength: 30 })) {
        this.link = e.target.value;
      }
    },
    handleEditInput(item, val) {
      if(validateLength(val, { minLength: 1, maxLength: 30 })) {
        item.newName = val;
      }
    },
    async getLinks() {
      const projectId = this.$route.params.id;
      try {
        const resp = await getReferralLinks(projectId);
        this.links = resp.data.referral_links.map(link => ({
          ...link,
          editing: false,
          newName: link.name,
        }));
      } catch(err) {
        console.log('Error fetching links: ', err);
      }
    },
    async generateLink() {
      const projectId = this.$route.params.id;
      this.generateError = null;
      if(!this.link) {
        const label = this.$t('manager.insights.referrers.tag_name').toLowerCase();
        this.generateError = this.$t('errors.VALUE_MISSING', { label });
        return;
      }
      try {
        await createReferralLink({
          projectId,
          name: this.link,
        });
        this.getLinks();
      } catch(err) {
        this.generateError = this.$t(fieldErrorKey(err.errors));
      }
      this.link = '';
    },
    async updateLink(link) {
      const projectId = this.$route.params.id;
      this.generateError = null;
      if(link.name === link.newName) {
        link.editing = false;
        return;
      }
      try {
        await updateReferralLink({
          projectId,
          linkId: link.id,
          name: link.newName,
        });
        link.editing = false;
      } catch(err) {
        this.generateError = this.$t(fieldErrorKey(err.errors));
      }
    },
    async deleteLink(linkId) {
      const projectId = this.$route.params.id;
      try {
        await deleteReferralLink({
          projectId,
          linkId,
        });
        this.getLinks();
      } catch(err) {
        console.log('Error deleting link: ', err);
      }
    },
  },
  mounted() {
    this.getLinks();
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.insights-referrers {
  width: 100%;
  min-height: 250px;

  .referrers-main {
    width: 65%;
    padding-right: 32px;
    min-width: 620px;
    margin-right: 10px;
  }
  .referrers-tags {
    width: 35%;
    min-width: 300px;
    margin-right: 10px;

    .tags-empty {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      @include h4;
      color: rgba(0, 0, 0, 0.3);
    }
    .tags-header {
      margin-bottom: 10px;
    }
    .tags-info {
      @include p_small;
      margin-bottom: 25px;
    }
    .tags-row, .tags-row-header {
      @include subtitle;
      display: flex;
      flex-direction: row;
      padding: 12px 0 9px 16px;
      box-sizing: border-box;
      border-bottom: 1px solid $border-light;
      > div {
        display: flex;
        flex: 1 0 auto;
        img, svg {
          height: 12px;
          cursor: pointer;
          opacity: 0.3;
          &.edit-icon {
            margin: 4px 0 0 8px;
          }
        }
        .accept-icon {
          display: flex;
          align-items: center;
          position: relative;
          right: 24px;
        }
      }
      .tags-copy {
        @include subtitle;
        color: $main-blue;
        cursor: pointer;
        flex: 0 0 auto;
        margin-right: 16px;
        justify-content: flex-end;
      }
      input {
        @include subtitle;
        color: $grey;
        outline: none;
        resize: none;
        border: none;
        padding: 5px;
        box-sizing: border-box;
        &::placeholder {
          opacity: 1;
        }
      }
    }
    .tags-row-header {
      border: 1px solid $border-light;
      input {
        padding: 0;
      }
      @media (max-width: $mobile-width) {
        > div {
          &:nth-child(1) {
            width: 50%;
          }
        }
      }
    }
    .tags-row {
      background-color: $grey-light;
    }
    .tags-list {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 200px;
      overflow-y: auto;
      background-color: $grey-light;
      border-width: 0 1px 1px;
      border-color: $border-light;
      border-style: solid;
    }
  }

  .insights-list.referrers-table {
    .header-item, .data-item {
      min-width: 110px;
    }
    .list-item-referrer {
      justify-content: flex-start;
      margin-left: 0;
      min-width: 200px;
      max-width: 200px;
    }
    .list-item-category {
      justify-content: flex-start;
      min-width: 90px;
      text-transform: capitalize;
    }
    .list-item-backers {
      min-width: 90px;
      margin-left: 0;
    }
  }
  @media (max-width: $tablet-width-large) {
    .referrers-main {
      width: 100%;
      padding-right: 0;
      min-width: unset;
    }
    .referrers-tags {
      width: 75%;
      margin: 24px auto 0;
    }
  }
  @media (max-width: $mobile-width) {
    .referrers-tags {
      width: 100%;
      min-width: unset;
    }
  }
}
</style>
