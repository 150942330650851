<template>
<div v-if="project" class="container manager-timeline">
  <div class="manager-timeline-left">
    <div v-if="active">
      <div class="manager-milestone-type type-top">
        {{ $t('manager.timeline.active') }}
      </div>
      <Milestone
        :milestone="active"
        :first="true"
        :only="!(upcoming.length || completed.length)"
        :completable="true"
        @mark-complete="selectedMilestone = active"
      />
    </div>
    <div v-if="upcoming.length" class="upcoming">
      <div
        class="manager-milestone-type"
        :class="{ 'type-top': !active }"
      >
        {{ $t('manager.timeline.upcoming') }}
      </div>
      <Milestone
        v-for="(milestone, idx) in upcoming"
        :key="milestone.id"
        :class="{ second: active && (idx === 0), inner: idx > 0 }"
        :milestone="milestone"
        :first="!active && (idx === 0)"
        :only="!active && !completed.length && (upcoming.length === 1)"
        :last="!completed.length && (idx === (upcoming.length - 1))"
      />
    </div>
    <div v-if="completed.length">
      <div
        class="manager-milestone-type"
        :class="{ 'type-top': !active && !upcoming.length }"
      >
        {{ $t('manager.timeline.completed') }}
      </div>
      <Milestone
        v-for="(milestone, idx) in completed"
        :key="milestone.id"
        :class="{ second: active && !upcoming.length && (idx === 0), inner: idx > 0 }"
        :milestone="milestone"
        :first="!active && !upcoming.length && (idx === 0)"
        :only="!active && !upcoming.length && (completed.length === 1)"
        :last="idx === (completed.length - 1)"
      />
    </div>
  </div>
  <div class="manager-timeline-right">
    <div class="transferred">
      {{ $t('manager.timeline.transferred') }}
    </div>
    <div class="transferred-amount">
      <span>{{ released }}</span>{{ $t('manager.timeline.transferred_amount') }}{{ total }}
    </div>
    <div class="right-text">
      {{ $t('manager.timeline.text1') }}
    </div>
    <div class="right-text">
      {{ $t('manager.timeline.text2') }}
    </div>
    <div class="right-text">
      {{ $t('manager.timeline.text3') }}
    </div>
  </div>
  <MilestoneCompleteModal
    v-if="selectedMilestone"
    :milestone="selectedMilestone"
    @accept="selectedMilestone = null"
    @close-modal="selectedMilestone = null"
  />
</div>
</template>

<script>
import moment from 'moment';
import Milestone from '@/components/builder/Milestone.vue';
import MilestoneCompleteModal from '@/components/manager/MilestoneCompleteModal.vue';

export default {
  name: 'manager-timeline',
  components: {
    Milestone,
    MilestoneCompleteModal,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedMilestone: null,
    };
  },
  computed: {
    milestones() {
      return this.project.milestones || [];
    },
    future() {
      const now = moment();
      return this.milestones.filter(m => m.date.isAfter(now));
    },
    released() {
      return this.$options.filters.from_cents(0);
    },
    total() {
      return this.$options.filters.from_cents(this.project.total_pledged);
    },
    active() {
      if(this.future.length) {
        return this.future[0];
      }
      return null;
    },
    upcoming() {
      if(this.future.length) {
        return this.future.slice(1);
      }
      return [];
    },
    completed() {
      const now = moment();
      return this.milestones.filter(m => m.date.isBefore(now));
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.manager-timeline {
  display: flex;
  .manager-timeline-left {
    width: 65%;
    box-sizing: border-box;
    padding-right: 32px;
    .manager-milestone-type {
      @include h3;
      margin-top: 40px;
      &.type-top {
        margin-top: 0;
      }
    }
    .milestone {
      padding-top: 24px;
      .bullet-wrap .bullet path {
        fill: $main-blue;
      }
    }
    .milestone.second .bullet-wrap .bullet-line {
      top: -96px;
    }
    .milestone.inner .bullet-wrap .bullet-line {
      top: -24px;
    }
  }
  .manager-timeline-right {
    width: 35%;
    .transferred {
      @include h5;
    }
    .transferred-amount {
      @include h4;
      color: $black-light50;
      margin: 8px 0 24px;
      span {
        @include h2;
        color: $main-black;
      }
    }
    .right-text {
      @include p_small;
      margin-top: 16px;
    }
  }
}
</style>
