<template>
<div class="insights-section campaign-insight">
  <Collapsible
    :collapsible="status !== 'open'"
  >
    <template #header>
      <div class="insights-summary">
        <div>
          <div class="summary-header">
            {{ $t('manager.insights.metrics') }}
          </div>
          <div class="summary-details">
            <div>{{ $t('manager.insights.ended', { date: endDate }) }}</div>
            <div>{{ $tc('manager.insights.pledged', totalPledgedFormatted) }}</div>
            <div>{{ $tc('manager.insights.funded', fundingPercentage) }}</div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <CampaignMetrics
        :project="project"
        :status="status"
      />
      <div class="insights-content">
        <div class="funding-progress">
          <div class="insights-header">
            {{ $t('manager.insights.progress') }}
          </div>
          <Graph
            :graphData="fundingData"
            :start="project.start_time"
            :end="project.end_time"
            :goal="project.funding_goal / 100"
            :totalPledged="totalPledged"
            :numRows="5"
            :status="status"
            type="funding"
            :topLineColor="status === 'progress' ? '#2e40ea' : 'green'"
            :topTextColor="status === 'progress' ? '#2e40ea' : '#e6e6e6'"
          />
        </div>
        <Sources :sources="sources" />
      </div>
      <Referrers :project="project" :referrers="referrers" />
      <Rewards :projectId="project.id" />
    </template>
  </Collapsible>
</div>
</template>

<script>
import Collapsible from '@/components/widget/Collapsible.vue';
import CampaignMetrics from '@/components/manager/CampaignMetrics.vue';
import Graph from '@/components/manager/Graph.vue';
import Sources from '@/components/manager/Sources.vue';
import Referrers from '@/components/manager/Referrers.vue';
import Rewards from '@/components/manager/Rewards.vue';

import { formatWithCommas } from '@/utils/stringUtils';
import { getFundingAnalytics } from '@/utils/api';

export default {
  name: 'campaign-insight',
  components: {
    Collapsible,
    CampaignMetrics,
    Graph,
    Referrers,
    Rewards,
    Sources,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      funding: [],
      referrers: [],
    };
  },
  computed: {
    fundingData() {
      return this.funding.map(fund => fund.total_usd / 100);
    },
    endDate() {
      const { end_time: endTime } = this.project;
      return endTime.format('MMM DD');
    },
    totalPledgedFormatted() {
      return formatWithCommas(this.totalPledged);
    },
    fundingPercentage() {
      const fundingGoal = this.project.funding_goal || 1;
      const percent = (this.totalPledged / fundingGoal) * 100;
      return percent.toFixed();
    },
    totalPledged() {
      return this.project.total_pledged / 100;
    },
    sources() {
      const sums = { INTERNAL: 0, EXTERNAL: 0, CUSTOM: 0, NONE: 0 };
      this.referrers.forEach((referrer) => {
        if(referrer.type === 'EXTERNAL' || referrer.type === 'INTERNAL' || referrer.type === 'NONE') {
          sums[referrer.type] += referrer.pledge_amount;
        } else {
          sums.CUSTOM += referrer.pledge_amount;
        }
      });
      const pledgedPercent = (sourceTotal) => {
        if(!this.project.total_pledged) {
          return '0';
        }
        return ((sourceTotal * 100) / this.project.total_pledged).toFixed(2);
      };
      return [
        {
          name: this.$t('manager.insights.sources.pledgecamp'),
          total_usd: sums.INTERNAL / 100,
          percent: pledgedPercent(sums.INTERNAL),
        },
        {
          name: this.$t('manager.insights.sources.external'),
          total_usd: sums.EXTERNAL / 100,
          percent: pledgedPercent(sums.EXTERNAL),
        },
        {
          name: this.$t('manager.insights.sources.custom'),
          total_usd: sums.CUSTOM / 100,
          percent: pledgedPercent(sums.CUSTOM),
        },
        {
          name: this.$t('manager.insights.sources.none'),
          total_usd: sums.NONE / 100,
          percent: pledgedPercent(sums.NONE),
        },
      ];
    },
  },
  async mounted() {
    const { data: { referrers, funding } } = await getFundingAnalytics(this.project.id);
    this.referrers = referrers;
    this.funding = funding;
  },
};
</script>
