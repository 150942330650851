<template>
<Modal
  :title="$t('manager.backers.export.title')"
  :submitable="false"
  class="export-modal"
  @submit="exportBackers(true)"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="content-paragraph">
      {{ $t('manager.backers.export.text') }}
    </div>
    <div class="error-message">
      {{ error }}
    </div>
    <div class="export-buttons">
      <PButton
        :animate="allLoading"
        class="export-button"
        :disabled="currentLoading"
        @click="exportBackers(true)"
      >
        {{ $t('manager.backers.export.all') }}
      </PButton>
      <PButton
        :animate="currentLoading"
        class="export-button"
        :disabled="allLoading"
        @click="exportBackers(false)"
      >
        {{ $t('manager.backers.export.current') }}
      </PButton>
    </div>
  </template>
</Modal>
</template>

<script>
import moment from 'moment';
import Modal from '@/components/widget/Modal.vue';
import PButton from '@/components/widget/PButton.vue';
import { exportPledges } from '@/utils/api';
import { saveFile } from '@/utils/docUtils';

export default {
  components: {
    Modal,
    PButton,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    selectedPledges: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      error: null,
      currentLoading: false,
      allLoading: false,
    };
  },
  methods: {
    async exportBackers(all) {
      if(all) {
        this.allLoading = true;
      } else {
        this.currentLoading = true;
      }
      try {
        const { data } = await exportPledges(this.projectId, {
          all,
          pledge_ids: this.selectedPledges.map(p => p.id),
        });
        const name = `${moment().format('YYYY-MM-DD_HH:mm:ss')}_${this.projectId}_pledges.tsv`;
        saveFile(name, data, 'text/tsv');
        this.$emit('close-modal');
      } catch(e) {
        this.error = 'Failed to export pledges.';
      }
      this.currentLoading = false;
      this.allLoading = false;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.modal-outer.export-modal .modal-inner {
  max-width: 480px;
  padding-bottom: 32px;

  .content-paragraph {
    text-align: center;
    padding-top: 16px;
  }
  .error-message {
    @include error;
    min-height: 40px;
    justify-content: center;
    padding: 0;
  }
  .export-buttons {
    display: flex;
    justify-content: space-between;
  }
  .export-button {
    @include button($main-black, full);
    width: 45%;
  }
}
</style>

