<template>
<div v-if="pledges" class="backer-table-wrap">
  <div class="table-header">
    <div class="header-left">
      <div class="table-search">
        <Search :size="24" :strokeWidth="1" />
        <div class="search-wrap">
          <input
            v-model="searchText"
            class="search-input"
            :placeholder="$tc('manager.backers.search_backers', pagination.total_count)"
            maxLength="20"
          >
        </div>
      </div>
      <div class="search-filters">
        <FilterMenu
          v-model="rewards"
          :title="$t('rewards.title')"
          itemsClass="filter-rewards"
        />
        <FilterMenu
          v-if="showDestinations"
          v-model="destinationData"
          :searchable="true"
          :title="$t('manager.backers.destination')"
          :loading="destinationsLoading"
          itemsClass="filter-destination"
          wrapperClass="destinations"
          @toggle="loadDestinations"
        />
        <FilterMenu
          v-model="paymentData"
          :title="$t('manager.backers.payment')"
          itemsClass="filter-payment"
        />
        <FilterMenu
          v-model="statusData"
          :title="$t('manager.backers.status')"
          itemsClass="filter-status"
        />
      </div>
    </div>
    <div class="filter-bar-wrap">
      <div class="search-filters-tablet">
        <div class="filter-wrap">
          <div
            class="filter-button"
            :class="filtersChecked && 'filter-checked'"
            @click="handleMobileFiltersClick"
          >
            {{ $tc('manager.backers.filters', filterCount) }}
          </div>
          <div
            v-if="mobileFiltersOpen"
            class="menu-dropdown"
            :class="{'filter-mobile': true, 'mobile-checked': filtersChecked}"
          >
            <div>
              <div class="filter-item">
                <div class="mobile-item">
                  <div>
                    {{ $t('manager.backers.destination') }}
                  </div>
                  <div class="backer-chevron" />
                </div>
              </div>
              <FilterMenu
                v-if="showDestinations"
                v-model="destinationData"
                :title="$t('manager.backers.destination')"
                :loading="destinationsLoading"
                wrapperClass="destinations"
                :mobile="true"
                @toggle="loadDestinations"
              />
            </div>
            <div>
              <div class="filter-item">
                <div class="mobile-item">
                  <div>
                    {{ $t('manager.backers.payment') }}
                  </div>
                  <div class="backer-chevron" />
                </div>
              </div>
              <FilterMenu
                v-model="paymentData"
                :title="$t('manager.backers.payment')"
                :mobile="true"
              />
            </div>
            <div>
              <div class="filter-item">
                <div class="mobile-item">
                  <div>
                    {{ $t('manager.backers.status') }}
                  </div>
                  <div class="backer-chevron" />
                </div>
              </div>
              <FilterMenu
                :items="statusData"
                :title="$t('manager.backers.status')"
                :mobile="true"
              />
            </div>
          </div>
        </div>
      </div>
      <BackerSegments
        :show="filtersChecked"
        :projectId="project.id"
        :filterData="filterData"
        @clear-filters="clearFilters"
        @select-segment="selectSegment"
      />
    </div>
  </div>
  <div
    v-if="selectedBackers.length"
    class="selected-options"
  >
    <div class="info">
      <Checkbox
        :item="{ label: $tc('manager.backers.backers_selected', selectedBackers.length) }"
        checkedClass="partial"
        class="backers-selected"
      />
    </div>
    <div class="option-buttons">
      <div v-on-clickaway="closeStatusMenu" class="option">
        <div class="button change-status" @click="toggleStatusMenu">
          {{ $t('manager.backers.change_status') }}
        </div>
        <div v-if="statusMenuOpen" class="menu-wrap">
          <div
            v-for="(item, index) in statusData"
            :key="index"
            class="menu-item"
            @click="handleStatusSelect(item)"
          >
            {{ item.title }}
          </div>
        </div>
        <div v-if="statusConfirmOpen" class="status-confirm">
          <div class="confirm-text">
            {{ $tc('manager.backers.confirm_status', selectedBackers.length, { status: selectedStatus.title}) }}
          </div>
          <div class="confirm-buttons">
            <div @click="statusConfirmOpen = false; selectedStatus = null">
              {{ $t('manager.backers.cancel') }}
            </div>
            <div @click="updateShippingMany">
              {{ $t('manager.backers.confirm') }}
            </div>
          </div>
        </div>
      </div>
      <div class="button export" @click="showExportModal = true">
        {{ $t('manager.backers.export.title') }}
      </div>
      <a :href="`mailto:?bcc=${selectedBackerEmails}`" target="_blank">
        <div class="button">
          {{ $t('manager.backers.contact') }}
        </div>
      </a>
    </div>
  </div>
  <BackersList
    :items="pledges"
    :project="project"
    :shippingStatusLoading="shippingStatusLoading"
    @selected="handleSelected"
    @sort="sortData = $event"
    @update-shipping="updateShipping"
  />
  <BackerExportModal
    v-if="showExportModal"
    :projectId="project.id"
    :selectedPledges="selectedBackers"
    @close-modal="showExportModal = false"
  />
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';

import FilterMenu from '@/components/manager/FilterMenu.vue';
import BackersList from '@/components/manager/BackersList.vue';
import BackerSegments from '@/components/manager/BackerSegments.vue';
import BackerExportModal from '@/components/manager/BackerExportModal.vue';
import Checkbox from '@/components/widget/Checkbox.vue';
import { CountryCodeMap } from '@/utils/constants';
import * as api from '@/utils/api';
import { formatPledge, filterNull, menuFromTranslation } from '@/utils/objectUtils';
import Search from '@/components/svg/Search.vue';

const initializeMenu = data => (
  data.map(d => ({ ...d, checked: false }))
);

export default {
  name: 'backers',
  components: {
    BackerSegments,
    BackerExportModal,
    BackersList,
    Checkbox,
    FilterMenu,
    Search,
  },
  mixins: [clickaway],
  props: {
    project: {
      type: Object,
      default: () => {},
    },
    rewardIdSelected: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      pledges: [],
      pagination: {
        page: 1,
        per_page: 20,
        total_count: 0,
        total_pages: 1,
      },
      showExportModal: false,
      statusData: menuFromTranslation(this.$t('manager.backers.status_filters')),
      shippingStatusLoading: false,
      paymentData: menuFromTranslation(this.$t('manager.backers.payment_filters')),
      destinationData: [],
      destinationsLoading: false,
      sortData: {
        sort_key: null,
        sort_order: 'desc',
      },
      rewardsMenuOpen: false,
      statusMenuOpen: false,
      statusConfirmOpen: false,
      selectedStatus: null,
      mobileFiltersOpen: false,
      selectedBackers: [],
      rewards: [],
    };
  },
  computed: {
    checkedVariants() {
      return this.rewards.map(r => (
        r.variants.filter(v => v.checked).map(v => v.id)
      )).flat();
    },
    selectedBackerEmails() {
      return this.selectedBackers.reduce((prev, cur) => (
        prev + (`${cur.user_email},` || '')
      ), '');
    },
    selectedPledgeIds() {
      return this.selectedBackers.map(b => b.id);
    },
    destinationChecked() {
      return this.destinationData.filter(d => d.checked).map(d => d.alpha3);
    },
    paymentChecked() {
      return this.paymentData.filter(p => p.checked).map(p => p.key);
    },
    statusChecked() {
      return this.statusData.filter(s => s.checked).map(s => s.key);
    },
    filterCount() {
      const {
        statusChecked,
        destinationChecked,
        paymentChecked,
        checkedVariants,
      } = this;
      return statusChecked.length + destinationChecked.length + paymentChecked.length + checkedVariants.length;
    },
    filtersChecked() {
      return !!this.filterCount;
    },
    filterData: {
      get() {
        return {
          shipping_status: this.statusChecked,
          destinations: this.destinationChecked,
          payment_status: this.paymentChecked,
          variant_ids: this.checkedVariants,
          search: this.searchText,
          ...filterNull(this.sortData),
        };
      },
      async set(newVal) {
        await this.loadDestinations(true);
        const { data } = newVal;
        this.statusData = this.statusData.map(s => ({
          ...s,
          checked: (data.shipping_status || []).includes(s.key),
        }));
        this.destinationData = this.destinationData.map(d => ({
          ...d,
          checked: (data.destinations || []).includes(d.alpha3),
        }));
        this.paymentData = this.paymentData.map(p => ({
          ...p,
          checked: (data.payment_status || []).includes(p.key),
        }));
        this.resetRewards(this.project, null, data.variant_ids);
        this.searchText = data.search || '';
      },
    },
    showDestinations() {
      return this.rewards.some(r => r.needs_shipping);
    },
  },
  watch: {
    project(newVal) {
      this.resetRewards(newVal);
    },
    filterData(newVal) {
      this.getPledges(newVal);
    },
    rewardIdSelected(rewardId) {
      this.resetRewards(this.project, rewardId);
    },
  },
  methods: {
    async getPledges(filterData) {
      const { data } = await api.getProjectPledges(this.project.id, filterData);

      this.pledges = data.pledges.map(formatPledge);
      this.pagination = data.pagination;
    },
    resetRewards(project, checkedRewardId, checkedVariantIds) {
      const vIds = checkedVariantIds || [];
      this.rewards = (project.rewards || {}).map((reward) => {
        const variants = reward.variants.map(variant => ({
          ...variant,
          checked: reward.id === checkedRewardId || vIds.includes(variant.id),
        }));
        return {
          ...reward,
          open: false,
          checked: reward.id === checkedRewardId || variants.some(v => v.checked),
          variants,
        };
      });
    },
    handleSelected(items) {
      const selected = items.filter(item => item.selected);
      this.selectedBackers = selected;
      this.pledges = items;
    },
    closeMenus() {
      this.rewardsMenuOpen = false;
      this.statusConfirmOpen = false;
      this.statusMenuOpen = false;
    },
    toggleRewardsMenu(e) {
      e.stopPropagation();
      this.rewardsMenuOpen = !this.rewardsMenuOpen;
    },
    closeRewardsMenu() {
      this.rewardsMenuOpen = false;
    },
    escapeListener(event) {
      if(event.keyCode === 27) {
        this.closeMenus();
      }
    },
    closeStatusMenu() {
      this.statusMenuOpen = false;
      this.statusConfirmOpen = false;
    },
    toggleStatusMenu() {
      this.statusMenuOpen = !this.statusMenuOpen;
    },
    handleStatusSelect(item) {
      this.closeStatusMenu();
      if(item.key) {
        this.selectedStatus = item;
        this.statusConfirmOpen = true;
      }
    },
    async updateShippingApi(ids, status) {
      this.shippingStatusLoading = true;
      try {
        await api.setPledgesShippingStatus(this.project.id, {
          pledge_ids: ids,
          shipping_status: status,
        });
      } catch(e) {
        console.log('SET SHIPPING', e);
      }
      this.shippingStatusLoading = false;
    },
    async updateShippingMany() {
      await this.updateShippingApi(this.selectedPledgeIds, this.selectedStatus.key);
      this.getPledges(this.filterData);
      this.statusConfirmOpen = false;
      this.selectedStatus = null;
    },
    async updateShipping({ index, data }) {
      const newKey = data.find(d => d.checked).key;
      const pledge = this.pledges[index];
      await this.updateShippingApi([pledge.id], newKey);
      this.$set(this.pledges, index, {
        ...pledge,
        shipping_status: newKey,
      });
    },
    clearFilters() {
      this.statusData = initializeMenu(this.statusData);
      this.destinationData = initializeMenu(this.destinationData);
      this.paymentData = initializeMenu(this.paymentData);
      this.searchText = '';
      this.resetRewards(this.project, []);
      this.closeMenus();
    },
    selectSegment(filterData) {
      this.filterData = filterData;
    },
    handleMobileFiltersClick() {
      this.mobileFiltersOpen = !this.mobileFiltersOpen;
    },
    async loadDestinations(active) {
      if(!active || this.destinationsLoading || this.destinationData.length > 0) {
        return;
      }
      this.destinationsLoading = true;
      try {
        const dest = await api.getProjectPledgeDestinations(this.project.id);
        this.destinationData = initializeMenu(
          dest.map((d) => {
            const { name, alpha3 } = CountryCodeMap[d];
            return { title: name, alpha3 };
          }),
        );
      } catch(e) {
        console.log('DEST ERROR', e);
      }
      this.destinationsLoading = false;
    },
  },
  created() {
    window.addEventListener('keyup', this.escapeListener);
    this.resetRewards(this.project, this.rewardIdSelected);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.escapeListener);
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.menu-dropdown {
  position: absolute;
  top: 40px;
  background-color: $white;
  border: 1px solid $border-light;
  z-index: 100;

  &.filter-rewards {
    margin-left: -61%;
  }
  &.filter-destination {
    margin-left: -43%;
  }
  &.filter-payment {
    margin-left: -60%;
  }
  &.filter-status {
    margin-left: -37%;
  }
  &.rewards-checked {
    margin-left: -40%;
  }
  &.destination-checked {
    margin-left: -28%;
  }
  &.payment-checked {
    margin-left: -40%;
  }
  &.status-checked {
    margin-left: -25%;
  }
  &.filter-mobile {
    margin-left: -80%;
  }
  &.mobile-checked {
    margin-left: 0;
    @media (min-width: $tablet-width) {
      margin-left: -57%;
    }
  }

  .filter-item {
    @include button_small_text;
    display: flex;
    width: 225px;
    min-height: 54px;
    align-items: center;
    font-weight: 600;
    padding-left: 16px;
    cursor: pointer;
    &:not(:last-child) {
      border-bottom: 1px solid $border-light;
    }

    .mobile-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: none;
    }
  }

  .reward {
    &:not(:last-child) {
      border-bottom: 1px solid $border-light;
    }
  }

  .destinations {
    overflow: scroll;
    height: 250px;
  }
}

.backer-table-wrap {
  margin-bottom: 140px;
  .table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .header-left {
      display: flex;
      flex-direction: row;
    }

    .search-filters {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .search-filters-tablet {
      float: left;
      display: none;
    }
  }
  .selected-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    padding: 10px 0 5px;

    .info {
      padding: 8px 0 0 14px;
      margin: 0 5px 10px 0;
      .backers-selected .checkbox-text {
        @include button_large_text;
        font-weight: 600;
      }
    }
    .option-buttons {
      display: flex;
      .option {
        position: relative;
      }
      .change-status, .export {
        margin-right: 14px;
      }
      .button {
        @include blue-button($type: secondary);
        padding: 3px 10px 0;
      }
      .menu-wrap {
        position: absolute;
        top: 45px;
        left: 16px;
        background-color: $white;
        border: 1px solid $border-light;
        z-index: 11;

        .menu-item {
          @include button_small_text;
          cursor: pointer;
          color: $main-blue;
          font-weight: 600;
          padding: 15px;
          width: 150px;
          &:not(:last-child) {
            border-bottom: 1px solid $border-light;
          }
          &:last-child {
            color: $main-orange;
          }
        }
      }
      .status-confirm {
        position: absolute;
        width: 240px;
        margin-left: -29px;
        top: 45px;
        background-color: $white;
        border: 1px solid $border-light;
        @include button_small_text;
        font-weight: 600;
        z-index: 11;
        .confirm-text {
          line-height: 24px;
          padding: 10px 15px;
          border-bottom: 1px solid $border-light;
        }
        .confirm-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          > div {
            text-align: center;
            padding: 18px 0;
            width: 100%;
            cursor: pointer;
            color: $main-blue;
            &:first-child {
              border-right: 1px solid $border-light;
              color: $main-orange;
            }
          }
        }
      }
    }
  }

  @media (max-width: $tablet-width-large) {
    .table-header {
      justify-content: flex-start;
      .search-filters {
        display: none;
      }
      .search-filters-tablet {
        display: block;
      }
    }
  }
}

.filter-wrap {
  position: relative;
  .filter-button {
    border: 1px solid $border-light;
    @include tab_item;
    line-height: 48px;
    height: 48px;
    padding: 0 15px;
    margin-right: 15px;

    &.filter-checked {
      background-color: $main-blue;
      color: $white;
    }
    &.menu-open {
      color: $main-black;
    }
  }
}

.backer-chevron {
  width: 6px;
  height: 6px;
  margin: 0 0 1px 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  cursor: pointer;
  &.ascending {
    margin-top: 5px;
    transform: rotate(-135deg);
  }
}

.table-search {
  min-width: 184px;
  margin-right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .search-input {
    @include subtitle;
    border: none;
    outline: none;
    resize: none;
    overflow: auto;
    margin-left: 8px;
  }

  .search-wrap {
    padding-top: 4px;
    @media (max-width: $mobile-width) {
      > input {
        &::placeholder {
          color: transparent;
        }
      }
    }
  }

  @media (max-width: $mobile-width) {
    min-width: unset;
    margin-right: unset;
    max-width: 100px;
  }
}

</style>
