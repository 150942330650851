<template>
<div v-if="project" class="manager-shipping">
  <div class="manager-shipping-left">
    <h3 class="shipping-title">
      {{ $t(`${rewardsKey}.title`) }}
    </h3>
    <div v-if="!rewards.length" class="shipping-no-rewards">
      {{ $t('manager.shipping.no_rewards') }}
    </div>
    <div v-else class="shipping-rewards">
      <div
        v-for="reward in rewards"
        :key="reward.id"
        class="shipping-reward"
      >
        <div class="shipping-reward-text">
          <div class="shipping-reward-title">
            {{ reward.title }}
          </div>
          <div class="shipping-reward-amount">
            {{ rewardShipped(reward) }} {{ $t('shipped') }}
          </div>
        </div>
        <div class="shipping-reward-view" @click="rewardClick(reward.id)">
          {{ $t('manager.shipping.view_orders') }}
        </div>
      </div>
    </div>
  </div>
  <div class="manager-shipping-right">
    <h3 class="shipping-title">
      {{ $t('manager.shipping.status') }}
    </h3>
    <div class="shipping-mark">
      <img :src="require('@/static/img/icons/ic_shipping.png')">
      <Checkbox
        :item="{ checked: shipping, label: $t('manager.shipping.shipping')}"
        :onCheck="onCheckShipping"
        :disabled="shippingDisabled"
      />
    </div>
    <div class="shipping-text">
      {{ $t('manager.shipping.shipping_text1') }}
    </div>
    <div class="shipping-text">
      {{ $t('manager.shipping.shipping_text2') }}
    </div>
  </div>
</div>
</template>

<script>
import Checkbox from '@/components/widget/Checkbox';
import { ProjectStatus } from '@/utils/constants';
import { updateProject } from '@/utils/api';

export default {
  name: 'manager-shipping',
  components: {
    Checkbox,
  },
  props: {
    project: {
      type: Object,
      default: null,
    },
    rewardIdSelected: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      shipping: false,
    };
  },
  computed: {
    rewardsKey() {
      return this.project.crowdfunding ? 'rewards' : 'products';
    },
    shippingDisabled() {
      const { status } = this.project;
      return status === ProjectStatus.INITIAL || status === ProjectStatus.DRAFT;
    },
    rewards() {
      const rewards = this.project.rewards || [];
      return rewards.filter(r => !r.is_donation);
    },
  },
  watch: {
    project(newVal) {
      this.shipping = newVal.is_shipping;
    },
  },
  methods: {
    onCheckShipping(checked) {
      try {
        updateProject({
          projectId: this.project.id,
          projectData: { is_shipping: checked },
        });
        this.shipping = checked;
      } catch(e) {
        console.log(e);
      }
    },
    rewardClick(rewardId) {
      this.$emit('update:rewardIdSelected', rewardId);
      this.$emit('set-tab', 'backers');
    },
    rewardShipped(reward) {
      if(reward.backer_limit > 0) {
        return `0 / ${reward.backer_limit}`;
      }
      return '0';
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.manager-shipping {
  display: flex;
  padding-bottom: 96px;

  .manager-shipping-left {
    width: 65%;
    box-sizing: border-box;
    padding-right: 32px;
    .shipping-rewards {
      .shipping-reward {
        padding: 20px 24px 16px;
        margin-top: 16px;
        border: 1px solid #eee;
        display: flex;
        align-items: center;
        .shipping-reward-title {
          @include h4;
        }
        .shipping-reward-amount {
          @include h5;
          color: $black-light50;
          margin-top: 8px;
        }
        .shipping-reward-view {
          @include button_small_text;
          margin-left: auto;
          color: $main-blue;
          cursor: pointer;
        }
      }
    }
  }
  .manager-shipping-right {
    width: 35%;
    .shipping-mark {
      margin-top: 16px;
      .checkbox-text {
        @include subtitle;
        color: $main-blue;
        padding-left: 40px;
      }
      img {
        width: 20px;
        position: relative;
        left: 30px;
        top: 35px;
        pointer-events: none;
      }
    }
    .shipping-text {
      @include p_small;
      color: $black-med;
      margin-top: 24px;
    }
  }
}
</style>
