<template>
<div class="filter-wrap">
  <div v-if="!mobile" class="filter-wrap-desktop">
    <div
      class="filter-button"
      :class="{'filter-checked': itemsChecked, 'menu-open': isActive && !itemsChecked}"
      @click="toggleMenu"
    >
      <slot name="title">
        {{ `${title} ${itemsChecked ? `(${itemsChecked})` : ''}` }}
      </slot>
    </div>
    <div
      v-if="isActive"
      v-on-clickaway="closeMenu"
      class="menu-dropdown"
      :class="itemsClass"
    >
      <div v-if="searchable && value" class="table-search">
        <input
          v-model="searchText"
          class="search-input"
          :placeholder="$tc('manager.backers.filter_menu.search', value.length)"
          maxLength="20"
        >
      </div>
      <div :class="wrapperClass">
        <Spinner v-if="loading" :size="40" />
        <div
          v-for="(item, index) in searchedItems"
          v-else
          :key="index"
          class="filter-item"
          @click="handleCheck(item)"
        >
          <div class="top-item">
            <Checkbox
              :item="{ label: item.title, checked: item.checked }"
            />
            <div
              v-if="hasVariants(item)"
              class="chevron-wrap"
              @click="e => toggleVariantsMenu(e, item)"
            >
              <div
                class="backer-chevron"
                :class="item.open ? '' : 'ascending'"
              />
            </div>
          </div>
          <div v-if="item.open">
            <div
              v-for="(variant, idx) in item.variants"
              :key="idx"
              class="filter-item filter-subitem"
              @click="e => handleVariantCheck(e, item, idx)"
            >
              <Checkbox
                :item="{ label: variant.name, checked: variant.checked }"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="mobile && isActive"
    class="menu-dropdown mobile"
  >
    <div :class="wrapperClass">
      <Spinner v-if="loading" :size="32" />
      <div
        v-for="(item, index) in searchedItems"
        v-else
        :key="index"
        class="filter-item-mobile"
        @click="handleCheck(item)"
      >
        <div>
          <Checkbox
            :item="{ checked: item.checked }"
            :onCheck="() => handleCheck(item)"
          />
        </div>
        <div class="item">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import Checkbox from '@/components/widget/Checkbox';
import Spinner from '@/components/widget/Spinner';

export default {
  name: 'filter-menu',
  components: {
    Checkbox,
    Spinner,
  },
  mixins: [clickaway],
  props: {
    project: {
      type: Object,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    searchable: {
      type: Boolean,
    },
    title: {
      type: String,
      default: null,
    },
    itemsClass: {
      type: String,
      default: null,
    },
    wrapperClass: {
      type: String,
      default: null,
    },
    mobile: Boolean,
    loading: Boolean,
    radioMode: Boolean,
    rewardSelected: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isActive: false,
      searchText: '',
      searchedItems: [],
    };
  },
  computed: {
    itemsChecked() {
      return this.indexedItems.reduce((prev, cur) => {
        if(this.hasVariants(cur)) {
          return prev + cur.variants.reduce((vPrev, vCur) => vPrev + (vCur.checked ? 1 : 0), 0);
        }
        return prev + (cur.checked ? 1 : 0);
      }, 0);
    },
    indexedItems() {
      return (this.value || []).map((item, index) => ({ ...item, index }));
    },
  },
  watch: {
    indexedItems(newValue) {
      this.updateSearchedItems(newValue);
    },
  },
  methods: {
    updateSearchedItems(items) {
      if(this.searchable && this.searchText) {
        const search = this.searchText.toLowerCase();
        this.searchedItems = items.filter(item => item.title.toLowerCase().includes(search));
      }
      items.forEach((item) => {
        if(item.title === this.rewardSelected) {
          item.checked = true;
        }
      });
      this.searchedItems = [...items];
    },
    handleCheck(item) {
      const newChecked = !item.checked;
      let newValue;
      if(this.radioMode) {
        if(!newChecked) {
          return;
        }
        newValue = this.value.map(v => ({ ...v, checked: false }));
      } else {
        newValue = [...this.value];
      }
      if(item.variants) {
        const newVariants = item.variants.map(v => ({ ...v, checked: newChecked }));
        newValue[item.index] = {
          ...item,
          variants: newVariants,
          checked: newChecked,
        };
      } else {
        newValue[item.index] = {
          ...item,
          checked: newChecked,
        };
      }
      this.$emit('input', newValue);
    },
    handleVariantCheck(e, item, index) {
      e.stopPropagation();
      const newValue = [...this.value];
      const newVariants = [...item.variants];
      newVariants[index] = {
        ...item.variants[index],
        checked: !item.variants[index].checked,
      };
      newValue[item.index] = {
        ...item,
        checked: newVariants.some(v => v.checked),
        variants: newVariants,
      };
      this.$emit('input', newValue);
    },
    closeMenu() {
      this.isActive = false;
      this.$emit('toggle', this.isActive);
    },
    toggleMenu() {
      this.isActive = !this.isActive;
      this.$emit('toggle', this.isActive);
    },
    escapeListener(event) {
      if(event.keyCode === 27) {
        this.closeMenu();
      }
    },
    hasVariants(item) {
      return item.variants && item.variants.length > 1;
    },
    toggleVariantsMenu(e, item) {
      e.stopPropagation();
      item.open = !item.open;
    },
  },
  mounted() {
    window.addEventListener('keyup', this.escapeListener);
    this.updateSearchedItems(this.indexedItems || {});
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.escapeListener);
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.filter-wrap .menu-dropdown {
  top: 52px;
  &.mobile {
    position: relative;
    top: 0;
    border: none;
  }
  .p-spinner-wrap {
    margin-top: 32px;
  }

  .filter-item {
    flex-direction: column;
    padding: 0;
    .chevron-wrap {
      cursor: pointer;
      width: 35px;
      height: 40px;
      padding-right: 16px;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    .top-item {
      width: 100%;
      display: flex;
      align-items: center;
      height: 50px;
      padding-left: 16px;
      .p-checkbox {
        margin: 0 16px 0 0;
      }
    }
    &.filter-subitem {
      background-color: $grey-lighter;
      padding: 0 0 0 24px;
      display: flex;
      flex-direction: row;
      &:not(:last-child) {
        border-bottom: 1px solid $border-light;
      }
    }
  }

  .table-search {
    padding-top: 4px;
  }

  .filter-item-mobile {
    @include button_small_text;
    font-weight: 600;
    padding: 15px;
    width: 225px;
    border-bottom: 1px solid $border-light;
    background-color: $grey-lighter;
    cursor: pointer;

    .item {
      padding-left: 25px;
      padding-top: 4px;
    }
  }
}

</style>
