import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

const goalBetweenPoints = (prevVal, currVal, goal) => (
  (goal >= prevVal) && (goal <= currVal)
);

const getPixels = (prevY, currY, prevVal, currVal, goal) => {
  const diffY = prevY - currY;
  const diffVal = currVal - prevVal;
  const ratio = diffVal - diffY;
  const pixels = (goal - prevVal) * ratio;
  return prevY + pixels;
};

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: {
    numRows: {
      type: Number,
      default: 5,
    },
    topLineColor: {
      type: String,
      default: '#2E40EA',
    },
    type: {
      type: String,
      default: 'funding',
    },
    goalLineValue: {
      type: Number,
      default: 0,
    },
    dataSet: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    const { numRows, topLineColor, type, goalLineValue, dataSet, options } = this;

    const topColor = topLineColor && !goalLineValue ? topLineColor : '#E6E6E6';
    const rows = numRows || 5;
    this.renderChart(this.chartData, options);
    this.addPlugin({
      id: 'chart-plugin',
      beforeDraw(chart) {
        const { canvas } = chart;
        canvas.style.height = '350px';
        // If mobile width, show fewer ticks
        if(window.innerWidth > 470) {
          chart.scales['x-axis-0'].options.ticks.minor.maxTicksLimit = 7;
        } else {
          chart.scales['x-axis-0'].options.ticks.minor.maxTicksLimit = 4;
        }
      },
      afterDraw(chart) {
        const {
          ctx,
          canvas,
        } = chart;
        const { width } = canvas.style;
        const lineOffset = type === 'funding' ? 24 : 25;
        const lineLength = width.slice(0, width.length - 2) - lineOffset;
        canvas.style['margin-left'] = '-20px';
        const chartHeight = chart.height - 30;
        const yAxis = chart.scales['y-axis-0'];

        yAxis.ticks.forEach((value, index) => {
          let lineColor = '#E6E6E6';
          let textColor = '#000';
          if(index === 0 && type === 'funding' && !goalLineValue) {
            textColor = topColor;
          }
          if(type === 'backer' && value === '50%') {
            textColor = '#fe4545';
            lineColor = '#fd9090';
          }
          ctx.save();
          ctx.beginPath();
          let y = index === 0 ? 0 : ((chartHeight / rows) * index);
          y = index === rows ? y - 4 : y;
          const x = 24;
          ctx.moveTo(x, y);
          ctx.strokeStyle = index === 0 ? topColor : lineColor;
          ctx.lineWidth = index === 0 ? 2 : 1;
          ctx.lineTo(lineLength, y);
          ctx.stroke();
          const xOffset = type === 'funding' ? 24 : 36;
          const yOffset = ((chartHeight / (rows)) * index) + 20;
          ctx.font = '600 13px Harmonia';
          ctx.fillStyle = textColor;
          ctx.fillText(value, xOffset, yOffset);
          ctx.restore();
        });

        const points = chart.getDatasetMeta(0).data;
        const graphContainer = document.getElementById('insights-graph-wrap');

        // Draw the goal line first before drawing the points
        points.forEach((value, index) => {
          const { _model: { y } } = value;

          if(index !== 0) {
            const { _model: prevPoints } = points[index - 1];
            const idx = value._index; // eslint-disable-line
            const curValue = dataSet[idx];
            const prevValue = dataSet[idx - 1];
            if(goalLineValue && goalBetweenPoints(prevValue, curValue, goalLineValue)) {
              const yVal = getPixels(prevPoints.y, y, prevValue, curValue, goalLineValue);
              ctx.save();
              ctx.beginPath();
              ctx.moveTo(24, yVal);
              ctx.strokeStyle = topLineColor;
              ctx.lineWidth = 1;
              ctx.lineTo(lineLength, yVal);
              ctx.stroke();
              ctx.restore();
            }
          }
        });

        points.forEach((value, index) => {
          const { _model: { x, y } } = value;
          if(index !== 0) {
            const { _model: prevPoints } = points[index - 1];
            ctx.beginPath();
            ctx.moveTo(prevPoints.x, prevPoints.y);
            ctx.strokeStyle = '#2E40EA';
            ctx.lineWidth = 2;
            ctx.lineTo(x, y);
            ctx.stroke();
            ctx.restore();
          }

          // Workaround for points getting cut off if at or near the top
          // https://github.com/chartjs/Chart.js/issues/3402

          if(type === 'funding') {
            if(y >= 0 && y <= 5) {
              const point = document.createElement('div');
              const id = `point-${index}`;
              const exists = document.getElementById(id);
              if(!exists) {
                point.id = id;
                point.style.position = 'absolute';
                point.style.left = `${x}px`;
                point.style.top = `${y}px`;
                point.style.width = '8px';
                point.style.height = '8px';
                point.style.backgroundColor = '#2E40EA';
                point.style.borderRadius = '50px';
                point.style.marginTop = '-4px';
                point.style.marginLeft = '-28px';
                point.style.pointerEvents = 'none';
                graphContainer.appendChild(point);
              }
            } else {
              ctx.beginPath();
              ctx.fillStyle = '#2E40EA';
              ctx.arc(x, y, 4, 0, 2 * Math.PI);
              ctx.fill();
              ctx.restore();
            }
          }
        });

        // Draw the x-axis labels manually to avoid issue of right-side cutoff
        const xAxis = chart.scales['x-axis-0'];
        const xTicks = xAxis.getTicks();
        const yBot = 388;

        xAxis.ticks.forEach((value, index) => {
          const currTick = xTicks[index];
          ctx.font = '600 14px Harmonia';
          ctx.fillStyle = '#808080';
          if(currTick.label) {
            const xOff = 4;
            const xPixel = xAxis.getPixelForTick(index) - xOff;
            if(xPixel < chart.width - 50) {
              ctx.fillText(value, xPixel, yBot);
              ctx.restore();
            }
          }
        });
      },
    });
  },
};
