<template>
<div class="insights-list-wrap">
  <div
    v-if="items"
    :class="tableClass"
    class="insights-list"
  >
    <div class="header-row">
      <div
        v-for="header in listHeaders"
        :key="header.title"
        class="header-item"
        :class="`header-item list-item-${header.dataKey}`"
        @click="sortData(header.sortKey || header.dataKey)"
      >
        <div>
          {{ $t(header.titleKey) }}
          <div v-if="showChevron(header)" class="header-chevron">
            <UpDownChevron :down="descending" />
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="(row, idx) in listData"
      :key="idx"
      class="data-row"
    >
      <div
        v-for="(item, i) in getItems(row, listHeaders)"
        :key="i"
        :class="`data-item list-item-${item.key}`"
      >
        {{ item.data }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UpDownChevron from '@/components/widget/UpDownChevron.vue';

export default {
  name: 'backers-list',
  components: {
    UpDownChevron,
  },
  props: {
    listHeaders: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    tableClass: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      selectedCategory: null,
      descending: true,
    };
  },
  computed: {
    listData() {
      const category = this.selectedCategory;
      if(!category || !this.items.length) {
        return this.items;
      }
      const sortType = typeof this.items[0][category];
      const items = this.items.slice().sort((a, b) => {
        const aVal = a[category];
        const bVal = b[category];
        if(sortType === 'string') {
          return aVal ? aVal.localeCompare(bVal) : 1;
        }
        if(aVal < bVal) {
          return -1;
        }
        if(aVal > bVal) {
          return 1;
        }
        return 0;
      });
      if(this.descending) {
        items.reverse();
      }
      return items;
    },
  },
  methods: {
    getItems(row, headers) {
      return headers.map(header => ({
        key: header.dataKey,
        data: row[header.dataKey],
      }));
    },
    sortData(category) {
      if(category === this.selectedCategory) {
        this.descending = !this.descending;
      } else {
        this.selectedCategory = category;
        this.descending = true;
      }
    },
    showChevron(header) {
      const category = this.selectedCategory;
      return header.dataKey === category || header.sortKey === category;
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.insights-list-wrap {
  overflow-x: scroll;
}
.insights-list {
  position: relative;
  display: inline-block;
  min-width: 100%;
  .data-row, .header-row {
    display: flex;
    box-sizing: border-box;
    padding: 0 12px;
  }
  .header-row {
    border-top: 1px solid $border-light;
    border-bottom: 1px solid $border-light;
    min-height: 50px;
  }
  .data-row {
    &:nth-child(even) {
      background-color: $grey-light;
    }
  }
  .header-item, .data-item {
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 4px 8px 0;
    @include h5;
    min-width: 140px;
    justify-content: flex-end;
    margin-left: auto;
    > div {
      position: relative;
    }
  }
  .header-item {
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    padding-top: 4px;
    .header-chevron {
      position: absolute;
      right: -14px;
      top: -1px;
      padding-left: 8px;
    }
  }
}
</style>
