<template>
<div class="pledge-item">
  <div class="item-info">
    <div class="item-image" :style="{ backgroundImage: `url(${product.imageUrl})`}" />
    <h4 class="item-name subtitle">
      {{ product.name }}
    </h4>
  </div>
  <div class="item-detail">
    <div class="item-quantity">
      <div v-if="!product.donation" class="item-quantity-edit" :class="{ enableEdit }">
        <h4>{{ product.quantity }}</h4>
        <UpDown
          v-if="enableEdit"
          :disabledDown="product.quantity <= 1"
          @up="$emit('increase-product', product)"
          @down="$emit('decrease-product', product)"
        />
      </div>
      <div v-else class="item-donation">
        {{ $t('checkout.rewards.donation') }}
      </div>
    </div>
    <h4 v-if="product.variantName" class="item-variant">
      {{ product.variantName }}
    </h4>
    <div class="item-cost">
      <h4>{{ (product.priceUsd * product.quantity) | from_cents }}</h4>
    </div>
  </div>
  <span
    v-if="enableEdit"
    class="btn-remove"
    @click="$emit('remove-product', product)"
  >
    <Cross />
  </span>
</div>
</template>

<script>
import UpDown from '@/components/svg/UpDown.vue';
import Cross from '@/components/svg/Cross.vue';

export default {
  name: 'pledge-item',
  components: {
    Cross,
    UpDown,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    enableEdit: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.pledge-item {
  width: 100%;
  display: flex;
  padding: 15px 0;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .item-info {
    display: flex;
    align-items: center;
    margin-right: 30px;
    flex-grow: 2;
  }
  .item-detail {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .item-image {
    width: 64px;
    height: 64px;
    background: #ddd;
    margin-right: 16px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }
  .item-quantity {
    .item-quantity-edit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.enableEdit {
        border: 1px solid $border-light;
      }
      h4 {
        width: 40px;
        text-align: center;
      }
      .p-up-down {
        border-left: 1px solid $border-light;
        > svg {
          margin: 6px 2px;
        }
      }
    }
    .item-donation {
      @include h5;
    }
  }
  .item-variant {
    min-width: 100px;
  }
  .item-cost {
    text-align: right;
    min-width: 64px;
  }
  .btn-remove {
    cursor: pointer;
    margin-left: 24px;
  }
  @media (max-width: $mobile-width) {
    .item-info, .item-detail {
      margin: 0;
    }
    .item-info {
      .item-image {
        display: none;
      }
    }
    .btn-remove {
      position: absolute;
      top: 20px;
      right: 0;
    }
  }
}
</style>
