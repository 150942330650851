<template>
<div class="pledge-rewards">
  <PledgeItem
    v-for="product in pledge.products"
    :key="product.id"
    :product="product"
    :changeable="projectActive"
    :enableEdit="false"
    @increase-product="$emit('increase-product', $event)"
    @decrease-product="$emit('decrease-product', $event)"
    @remove-product="$emit('remove-product', $event)"
  />
  <div v-if="projectActive" class="pledge-rewards-button">
    <button @click="$emit('add-more-rewards')">
      {{ $t('project.trust.reward_modal.add_more') }}
    </button>
  </div>
  <PledgeBilling
    :shippingCost="pledge.shipping_total"
    :discount="pledge.discount"
    :cartSubtotal="pledge.sub_total_usd"
    :currency="currency"
  />
</div>
</template>

<script>
import PledgeBilling from '@/components/pledge/PledgeBilling';
import PledgeItem from '@/components/pledge/PledgeItem';

export default {
  name: 'pledge-rewards',
  components: {
    PledgeBilling,
    PledgeItem,
  },
  props: {
    pledge: {
      type: Object,
      required: true,
    },
    projectActive: Boolean,
    currency: {
      type: String,
      default: 'USD',
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.pledge-rewards {
  .pledge-rewards-button {
    margin-top: 10px;
    button {
      @include link;
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .reward-label {
    color: grey;
  }
}

</style>
