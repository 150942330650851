<template>
<div class="insights-content">
  <div class="insights-rewards">
    <div class="insights-header">
      {{ $t('rewards.title') }}
      <div class="learn-more">
        {{ $t('learn_more') }}
      </div>
    </div>
    <InsightsList
      :items="rewardsData"
      :listHeaders="rewardHeaders"
      tableClass="rewards-table"
    />
  </div>
</div>
</template>

<script>
import InsightsList from '@/components/manager/InsightsList.vue';
import { GET_PROJECT_REWARD_ANALYTICS } from '@/store/actions';

export default {
  name: 'insights-rewards',
  components: {
    InsightsList,
  },
  props: {
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      rewardData: [],
      rewardHeaders: [
        {
          titleKey: 'manager.insights.rewards.reward',
          dataKey: 'reward',
        },
        {
          titleKey: 'manager.insights.rewards.backers',
          dataKey: 'backers',
        },
        {
          titleKey: 'manager.insights.pledges',
          dataKey: 'pledges',
        },
        {
          titleKey: 'manager.insights.rewards.avg_quantity',
          dataKey: 'average',
          sortKey: 'averageSort',
        },
        {
          titleKey: 'manager.insights.percent_pledged',
          dataKey: 'percentage',
          sortKey: 'percentageSort',
        },
        {
          titleKey: 'manager.insights.amount_pledged',
          dataKey: 'amount_pledged',
          sortKey: 'amountPledgedSort',
        },
      ],
    };
  },
  computed: {
    rewardsData() {
      const data = this.$store.getters.rewardAnalytics;
      const pledgeTotal = data.reduce((prev, cur) => prev + cur.total_usd, 0);
      return data.map((item) => {
        const percentageSort = item.total_usd / pledgeTotal;
        const averageSort = item.pledged_count / item.backer_count;
        return {
          reward: item.reward_variant.name,
          backers: item.backer_count,
          pledges: item.pledged_count,
          average: (averageSort).toFixed(1),
          averageSort,
          percentage: `${((percentageSort) * 100).toFixed(1)}%`,
          percentageSort,
          amount_pledged: this.$options.filters.from_cents(item.total_usd),
          amountPledgedSort: item.total_usd,
        };
      });

    },
  },
  mounted() {
    this.$store.dispatch(GET_PROJECT_REWARD_ANALYTICS, this.projectId);
  },
};
</script>

<style lang="scss">

table {
  th, td {
    &:first-child .p-checkbox-wrap {
      padding-bottom: 24px;
    }
  }
}
.insights-rewards {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.rewards-table {
  .list-item-reward {
    max-width: 35%;
    flex-grow: 1;
    justify-content: flex-start;
    margin-left: 0;
  }
  .list-item-backers {
    min-width: 90px;
  }
  .list-item-percentage {
    min-width: 110px;
  }
  .list-item-amount_pledged {
    min-width: 110px;
  }
}
</style>
