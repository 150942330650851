<template>
<div class="backers-list">
  <TitledSelect
    v-model="selectedColumn"
    type="select"
    class="mobile-right-select"
    :options="columnTitles"
    :title="$t('manager.backers.right_column')"
  />
  <div
    v-if="items"
    class="list-wrap"
  >
    <div class="list-column user_name">
      <div class="list-item">
        <Checkbox
          :item="{ checked: anySelected }"
          :onCheck="handleCheckAll"
        />
        <div class="header-item" @click="sortData(nameHeader)">
          <div>{{ nameHeader.title }}</div>
          <UpDownChevron v-if="showChevron(nameHeader)" :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`user_name_${idx}`"
        class="list-column-data"
      >
        <div class="list-item">
          <Checkbox
            :item="{ checked: pledge.selected }"
            :onCheck="() => handleCheck(pledge, idx)"
          />
          <div @click="selectedIndex = idx">
            <span>{{ pledge.user_name || pledge.user_email }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="shadow-left" />
    <div class="list-column created">
      <div
        class="list-item header-item"
        @click="sortData(dateHeader)"
      >
        <div>{{ dateHeader.title }}</div>
        <div v-if="showChevron(dateHeader)">
          <UpDownChevron :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`created_${idx}`"
        class="list-column"
      >
        <div class="list-item">
          {{ pledge.created }}
        </div>
      </div>
    </div>
    <div class="list-column sub_total_usd">
      <div
        class="list-item header-item"
        @click="sortData(amountHeader)"
      >
        <div>{{ amountHeader.title }}</div>
        <div v-if="showChevron(amountHeader)">
          <UpDownChevron :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`sub_total_usd_${idx}`"
        class="list-column"
      >
        <div class="list-item">
          {{ pledge.formattedSubtotal }}
        </div>
      </div>
    </div>
    <div class="list-column payment_status">
      <div
        class="list-item header-item"
        @click="sortData(paymentHeader)"
      >
        <div>{{ paymentHeader.title }}</div>
        <div v-if="showChevron(paymentHeader)">
          <UpDownChevron :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`payment_status_${idx}`"
        class="list-column"
      >
        <div
          class="list-item"
          :class="pledge.payment_status"
        >
          {{ pledge.payment_status }}
        </div>
      </div>
    </div>
    <div class="list-column shipped">
      <div
        class="list-item header-item"
        @click="sortData(statusHeader)"
      >
        <div>{{ statusHeader.title }}</div>
        <div v-if="showChevron(statusHeader)">
          <UpDownChevron :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`shipped_${idx}`"
        class="list-column"
      >
        <div class="list-item">
          <FilterMenu
            v-model="statusData"
            :radioMode="true"
            :title="$t('manager.backers.status')"
            :loading="shippingStatusLoading"
            @toggle="active => toggleShippingStatus(pledge, active)"
            @input="$emit('update-shipping', { index: idx, data: $event })"
          >
            <template #title>
              <div :class="`list-shipping ${pledge.shipping_status}`">
                <span>{{ $t(`manager.backers.status_filters.${pledge.shipping_status}`) }}</span>
                <div class="chevron-wrap">
                  <div class="chevron" />
                </div>
              </div>
            </template>
          </FilterMenu>
        </div>
      </div>
    </div>
    <div class="list-column user_email">
      <div
        class="list-item header-item"
        @click="sortData(emailHeader)"
      >
        <div>{{ emailHeader.title }}</div>
        <div v-if="showChevron(emailHeader)">
          <UpDownChevron :down="descending" />
        </div>
      </div>
      <div
        v-for="(pledge, idx) in items"
        :key="`user_email_${idx}`"
        class="list-column"
      >
        <div class="list-item">
          <a :href="`mailto:${pledge.user_email}`" target="_blank">
            <img
              :src="require('@/static/img/icons/ic_mail.png')"
              class="mail-icon"
            >
          </a>
          <div class="mobile-expand" @click="$emit('mobile-expand')">
            <img :src="require('@/static/img/icons/ic_right.png')">
          </div>
        </div>
      </div>
    </div>
  </div>
  <BackerModal
    v-if="selectedPledge"
    :shippingStatusLoading="shippingStatusLoading"
    :project="project"
    :pledgeId="selectedPledge.id"
    :pledgeIndex="selectedIndex"
    @update-shipping="$emit('update-shipping', { index: selectedIndex, data: $event })"
    @close-modal="selectedIndex = null"
  />
</div>
</template>

<script>
import Checkbox from '@/components/widget/Checkbox.vue';
import FilterMenu from '@/components/manager/FilterMenu.vue';
import UpDownChevron from '@/components/widget/UpDownChevron.vue';
import TitledSelect from '@/components/widget/TitledSelect.vue';
import BackerModal from '@/components/manager/BackerModal.vue';
import { menuFromTranslation } from '@/utils/objectUtils';

export default {
  name: 'backers-list',
  components: {
    Checkbox,
    FilterMenu,
    UpDownChevron,
    TitledSelect,
    BackerModal,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    project: {
      type: Object,
      required: true,
    },
    shippingStatusLoading: Boolean,
  },
  data() {
    return {
      selectedCategory: null,
      selectedIndex: null,
      descending: true,
      selectedColumn: this.$t('manager.backers.columns.amount'),
      nameHeader: { title: this.$t('manager.backers.columns.name'), type: 'user_name' },
      dateHeader: { title: this.$t('manager.backers.columns.date'), type: 'created' },
      amountHeader: { title: this.$t('manager.backers.columns.amount'), type: 'sub_total_usd' },
      paymentHeader: { title: this.$t('manager.backers.columns.payment'), type: 'payment_status' },
      statusHeader: { title: this.$t('manager.backers.columns.status'), type: 'shipping_status' },
      emailHeader: { title: this.$t('manager.backers.columns.email'), type: 'user_email' },
      statusData: menuFromTranslation(this.$t('manager.backers.status_filters')),
    };
  },
  computed: {
    columnTitles() {
      const titles = this.$t('manager.backers.columns');
      return Object.values(titles).filter(title => titles.name !== title);
    },
    mobileHeaders() {
      const selected = this.listHeaders.filter(header => header.title === this.selectedColumn)[0];
      return this.listHeaders.filter(header => header.type === 'user_name' || header.type === selected.type);
    },
    anySelected() {
      return this.items.some(item => item.selected);
    },
    selectedPledge() {
      if(this.selectedIndex === null) {
        return null;
      }
      return this.items[this.selectedIndex];
    },
  },
  methods: {
    sortData(header) {
      const category = header.sort_type || header.type;
      if(category !== 'contact') {
        if(category === this.selectedCategory) {
          this.descending = !this.descending;
        } else {
          this.selectedCategory = category;
          this.descending = true;
        }
        this.$emit('sort', {
          sort_key: category,
          sort_order: this.descending ? 'desc' : 'asc',
        });
      }
    },
    handleCheckAll() {
      const items = this.items.map((item) => {
        item.selected = !this.anySelected;
        return item;
      });
      this.$emit('selected', items);
    },
    handleCheck(pledge, idx) {
      pledge.selected = !pledge.selected;
      const items = this.items.slice();
      items[idx] = pledge;
      this.$emit('selected', items);
    },
    showChevron(header) {
      const category = this.selectedCategory;
      return category !== 'contact' && (header.type === category || header.sort_type === category);
    },
    toggleShippingStatus(pledge, active) {
      if(active) {
        this.statusData = this.statusData.map(d => (
          {
            ...d,
            checked: d.key === pledge.shipping_status,
          }
        ));
      }
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.backers-list {
  width: 100%;
  position: relative;
  min-height: 320px;
  border-top: 1px solid $border-light;
  .mobile-right-select {
    display: none;
  }
  .shadow-left {
    display: none;
  }

  .list-wrap {
    position: relative;
    display: flex;
    min-width: 100%;
    .list-column {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      > div:nth-child(even) {
        background-color: $grey-light;
      }
      > div:first-child {
        border-bottom: 1px solid $border-light;
      }
      .header-item, .list-item {
        display: flex;
        align-items: center;
        padding-top: 6px;
        box-sizing: border-box;
        height: 48px;
        @include h5;
      }
      .check-item {
        flex-grow: 0;
        padding-top: 2px;
        padding-left: 14px;
      }
      .header-item {
        color: rgba(0, 0, 0, 0.5);
        cursor: pointer;
        padding-top: 4px;
        .chevron {
          margin-left: 8px;
        }
      }
      &.user_name .list-item {
        padding: 0 4px 0 8px;
        min-width: 240px;
        cursor: pointer;
        span {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }
      &.created {
        min-width: 120px;
        border-left: 1px solid $border-light;
        .list-item {
          padding-left: 24px;
        }
      }
      &.sub_total_usd {
        min-width: 140px;
        .list-item {
          justify-content: flex-end;
          padding-right: 32px;
        }
      }
      &.user_email .list-item {
        padding-right: 16px;
        min-width: 80px;
        justify-content: flex-end;
        .mail-icon {
          height: 15px;
        }
      }
      &.payment_status {
        min-width: 140px;
        .list-item {
          justify-content: center;
        }
      }
      &.shipped {
        min-width: 140px;
        .list-item {
          justify-content: center;
          > span, > div {
            cursor: pointer;
          }
          .list-shipping {
            display: flex;
            align-items: center;
          }
        }
      }
      @include shipping-title;
    }
    .mobile-expand {
      display: none;
      height: 25px;
      width: 25px;
      margin-left: auto;
      > img {
        width: 12px;
        opacity: 0.5;
      }
    }
  }
  .Failed {
    color: $col-error;
  }

  @media (max-width: $tablet-width-large) {
    overflow: auto;
    .list-wrap {
      overflow-x: scroll;
    }
    .shadow-left {
      z-index: 3;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 40px;
      background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.06) 0%, rgba(0, 0, 0, 0) 100%);
      content: ".";
      color: transparent;
      left: 240px;
    }
  }
  @media (max-width: $mobile-width) {
    .shadow-left {
      display: none;
    }
    .list-wrap {
      .mobile-expand {
        display: block;
      }
    }
  }
}
</style>
