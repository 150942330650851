<template>
<div v-if="project" class="insights-metrics">
  <div class="detail">
    <div>{{ backerConfidence }}</div>
    <div>{{ $t('manager.insights.categories.confidence') }}</div>
  </div>
  <div class="detail">
    <div>{{ yesVotes }}<span class="cat">{{ maxVotes }}</span></div>
    <div>{{ $t('manager.insights.categories.voting') }}</div>
  </div>
  <div class="break-mobile" />
  <div class="detail">
    <div>{{ nextMilestone }}<span class="cat">{{ $t('days') }}</span></div>
    <div>{{ $t('manager.insights.categories.next') }}</div>
  </div>
  <div class="break-tablet" />
  <div class="detail">
    <div>{{ fundsReceived | from_cents }}</div>
    <div>{{ $t('manager.insights.categories.received') }}</div>
  </div>
  <div class="break-mobile" />
  <div class="detail">
    <div>{{ backerInsurance | from_cents }}</div>
    <div>{{ $t('manager.insights.categories.escrow') }}</div>
  </div>
</div>
</template>

<script>
import moment from 'moment';

export default {
  name: 'backer-metrics',
  props: {
    project: {
      type: Object,
      default: null,
    },
  },
  computed: {
    backerConfidence() {
      return (this.maxVotes - this.yesVotes) / this.maxVotes;
    },
    yesVotes() {
      // TODO: get votes from API
      return Math.floor(this.maxVotes * 0.43);
    },
    maxVotes() {
      return this.project.backer_count;
    },
    pledged() {
      return this.project.total_pledged / 100;
    },
    fundsReceived() {
      // TODO -- calculate based on milestones
      return this.pledged * (100 - this.project.reserve_percent);
    },
    backerInsurance() {
      return this.pledged * this.project.reserve_percent;
    },
    nextMilestone() {
      const { milestones } = this.project;
      const today = moment();
      const sorted = milestones.slice()
        .sort((m1, m2) => m1.date - m2.date)
        .filter(m => moment(m.date * 1000).isAfter(today));
      if(!sorted.length) return 0;
      const next = sorted[0].date * 1000;
      const days = moment(next).diff(moment(today), 'days');
      return days;
    },
  },
};
</script>
