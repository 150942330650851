<template>
<div v-if="project" class="insights-metrics">
  <div class="detail">
    <div>{{ fundingTotal }}</div>
    <div>{{ $t('manager.insights.categories.pledged') }}</div>
  </div>
  <div class="detail detail-even">
    <div>{{ `${fundingPercent}%` }}</div>
    <div>{{ $t('manager.insights.categories.goal') }}</div>
  </div>
  <div class="break-mobile" />
  <!--  TODO: need to refactor breaks to accommodate conditional rendering of diff metrics -->
  <div v-if="status === 'open'" class="detail">
    <div>{{ days }}</div>
    <div>{{ $t('manager.insights.categories.left') }}</div>
  </div>
  <div class="break-tablet" />
  <div class="detail" :class="status === 'open' ? 'detail-even' : ''">
    <div>{{ project.backer_count }}</div>
    <div>{{ $t('manager.insights.categories.backers') }}</div>
  </div>
  <div class="break-mobile" />
  <div class="detail" :class="{ 'detail-even': status !== 'open' }">
    <div>{{ averagePledge }}</div>
    <div>{{ $t('manager.insights.categories.average') }}</div>
  </div>
  <div v-if="status === 'progress'" class="detail">
    <div>{{ averagePledge }}</div>
    <div>{{ $t('manager.insights.categories.fee') }}</div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import { formatWithCommas } from '@/utils/stringUtils';

export default {
  name: 'campaign-metrics',
  props: {
    project: {
      type: Object,
      default: null,
    },
    status: {
      type: String,
      default: null,
    },
  },
  computed: {
    fundingPercent() {
      const pledged = this.project.total_pledged || 0;
      const goal = this.project.funding_goal || 1;
      return parseInt((pledged * 100) / goal);
    },
    // Get funding total, abbreviated with M if over 1,000,000
    fundingTotal() {
      const amount = this.project.total_pledged / 100;
      let formatted;
      if(amount > 1000000) {
        const abbrev = amount / 1000000;
        if(amount % 1000000 >= 100000) {
          formatted = abbrev.toFixed(1);
        } else {
          formatted = Math.floor(abbrev);
        }
        formatted = `${formatted}M`;
      } else {
        formatted = formatWithCommas(amount);
      }
      return `$${formatted}`;
    },
    averagePledge() {
      const {
        total_pledged: totalPledged,
        backer_count: backerCount,
      } = this.project;
      if(backerCount <= 0) {
        return 0;
      }
      return `$${(totalPledged / (100 * backerCount)).toFixed(2)}`;
    },
    days() {
      const date = this.project.end_time;
      return date ? date.diff(moment(), 'days') : 0;
    },
  },
};
</script>
