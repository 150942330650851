<template>
<div class="pledge-items-billing">
  <div class="pledge-billing-item">
    <h5 class="label">
      {{ $t('checkout.rewards.subtotal') }}
    </h5>
    <h5 class="value">
      {{ cartSubtotal | from_cents }}
    </h5>
  </div>
  <div v-if="needsShipping" class="pledge-billing-item">
    <h5 class="label">
      {{ $t('checkout.rewards.estimated_shipping') }}
    </h5>
    <h5 class="value">
      {{ shippingCost | from_cents }}
    </h5>
  </div>
  <div v-if="discount" class="pledge-billing-item discount">
    <h5 class="label">
      {{ $t('discount') }}
    </h5>
    <h5 class="value">
      {{ discountText }}
    </h5>
  </div>
  <div v-if="fromWallet" class="pledge-billing-item">
    <h5 class="label">
      {{ $t('checkout.payment.plg_wallet') }}
    </h5>
    <h5 class="value">
      {{ fromWalletText }}
    </h5>
  </div>
  <div class="pledge-billing-total-wrap">
    <div v-if="!hideTotal" class="pledge-billing-total">
      <h4 class="label">
        {{ $t('total') }}
      </h4>
      <h2 class="value">
        {{ total }}
      </h2>
    </div>
  </div>
</div>
</template>

<script>
import { priceDisplay } from '@/utils/objectUtils';

export default {
  name: 'pledge-billing',
  props: {
    needsShipping: Boolean,
    hideTotal: Boolean,
    shippingCost: {
      type: Number,
      default: 0,
    },
    discount: {
      type: Number,
      default: 0,
    },
    fromWallet: {
      type: Number,
      default: 0,
    },
    cartSubtotal: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: 'USD',
    },
  },
  computed: {
    total() {
      const amount = Math.max(0, (this.cartSubtotal + this.shippingCost) - (this.discount + this.fromWallet));
      return priceDisplay(amount, this.currency);
    },
    discountText() {
      return `- ${this.$options.filters.from_cents(this.discount)}`;
    },
    fromWalletText() {
      return `- ${this.$options.filters.from_cents(this.fromWallet)}`;
    },
  },
};
</script>

<style lang="scss">
.pledge-items-billing {
  border-top: 1px solid $border-light;
  padding-top: 16px;
  .label {
    color: $grey2;
    margin: 0;
  }
  .pledge-billing-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
    &.discount .value {
      color: $main-red;
    }
  }
  .pledge-billing-total-wrap {
    border-top: 1px solid $border-light;
  }
  .pledge-billing-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 16px;
  }
  @media (max-width: $mobile-width) {
    .pledge-billing-total {
      border-bottom: 1px solid $border-light;
    }
  }
}
</style>
