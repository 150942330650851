<template>
<Modal
  :title="$t('manager.timeline.modal.title')"
  :submitText="$t('manager.timeline.modal.button')"
  :cancelable="true"
  :cancelText="$t('cancel')"
  class="milestone-complete"
  @submit="$emit('accept')"
  @cancel="$emit('close-modal')"
>
  <template #content>
    <div class="milestone-complete-modal">
      {{ $t('manager.timeline.modal.text', { name: milestone.name }) }}
    </div>
  </template>
</Modal>
</template>

<script>
import Modal from '@/components/widget/Modal.vue';

export default {
  name: 'milestone-complete-modal',
  components: { Modal },
  props: {
    milestone: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@import 'general';

.modal-outer.milestone-complete {
  text-align: center;
  .modal-header {
    @include h3;
  }
  .modal-inner {
    padding-bottom: 16px;
    .milestone-complete-modal {
      box-sizing: border-box;
      padding: 32px 0;
      @include p_small;
    }
  }
}

</style>
