<template>
<div v-on-clickaway="closeMenus" class="segments-wrap">
  <div
    v-if="show"
    class="table-segments-clicked"
  >
    <div v-if="segmentSaved" class="saved">
      {{ $t('manager.backers.segment_saved') }}
    </div>
    <div
      v-else
      class="save"
      @click="saveMenuOpen = true"
    >
      {{ $t('manager.backers.save_segment') }}
    </div>
    <div class="clear" @click="$emit('clear-filters')">
      {{ $t('manager.backers.clear_filters') }}
    </div>
    <div
      v-if="saveMenuOpen"
      class="menu-dropdown segment"
    >
      <div class="segments">
        <div class="segment-input-wrap">
          <input
            v-model="name"
            class="segment-input"
            :placeholder="$t('manager.backers.segment_title')"
            maxLength="16"
          >
          <div
            class="save-segment"
            @click="saveSegment"
          >
            {{ $t('manager.backers.save') }}
          </div>
        </div>
        <ErrorMessage :errorMessage="error" />
        <Spinner v-if="loading" />
        <div v-else>
          <div class="segments-info">
            {{ $t('manager.backers.quickly') }}
          </div>
          <div class="cancel" @click="saveMenuOpen = false">
            {{ $t('manager.backers.cancel') }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="segments-select"
  >
    <div class="segment-title" @click="toggleSegmentMenu">
      {{ $t('manager.backers.segments') }}
      <div class="backer-chevron" />
    </div>
    <div
      v-if="segmentMenuOpen"
      class="menu-dropdown segment"
    >
      <Spinner v-if="loading" />
      <div v-else-if="segments.length" class="segments">
        <div
          v-for="(item, index) in segments"
          :key="index"
          class="segment-item"
        >
          <div @click="$emit('select-segment', item)">
            {{ item.name }}
          </div>
          <div @click="deleteSegment(item)">
            <img class="delete" :src="require('@/static/img/icons/ic_close.png')">
          </div>
        </div>
        <div class="segments-info">
          {{ $t('manager.backers.apply') }}
        </div>
      </div>
      <div v-else class="segments">
        <div class="empty-top">
          {{ $t('manager.backers.no_saved') }}
        </div>
        <div class="segments-info">
          {{ $t('manager.backers.to_save') }}
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway';
import ErrorMessage from '@/components/widget/ErrorMessage';
import Spinner from '@/components/widget/Spinner';
import * as api from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';

export default {
  name: 'backer-segments',
  components: {
    ErrorMessage,
    Spinner,
  },
  mixins: [clickaway],
  props: {
    show: Boolean,
    projectId: {
      type: Number,
      required: true,
    },
    filterData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      segments: [],
      loading: false,
      name: '',
      segmentSaved: false,
      segmentMenuOpen: false,
      saveMenuOpen: false,
      error: null,
    };
  },
  methods: {
    toggleSegmentMenu() {
      this.segmentMenuOpen = !this.segmentMenuOpen;
    },
    closeMenus(_e) {
      this.segmentMenuOpen = false;
      this.saveMenuOpen = false;
    },
    async saveSegment() {
      if(this.name.length < 1 || this.name.length > 16) {
        this.error = this.$t('errors.suberror_char_range', {
          label: this.$t('name'),
          min: 1,
          max: 16,
        });
        return;
      }
      this.loading = true;
      try {
        const { data } = await api.createPledgeSegment(this.projectId, {
          name: this.name,
          data: this.filterData,
        });
        this.segments = [data.backer_segment, ...this.segments];
      } catch(e) {
        this.error = this.$t(fieldErrorKey(e.errors));
      }
      this.loading = false;
      this.closeMenus();
    },
    async deleteSegment(item) {
      this.loading = true;
      try {
        await api.deletePledgeSegment(this.projectId, item.id);
        this.segments = this.segments.filter(s => s.id !== item.id);
      } catch(e) {
        this.error = this.$t(fieldErrorKey(e.errors));
      }
      this.loading = false;
    },
  },
  async mounted() {
    try {
      const { data } = await api.getPledgeSegments(this.projectId);
      this.segments = data.backer_segments;
    } catch(e) {
      this.error = this.$t(fieldErrorKey(e.errors));
    }
  },
};
</script>

<style lang="scss">
@import 'general';

.segments-wrap {
  .menu-dropdown {
    left: -110px;
  }
  .segment-title {
    display: flex;
    .backer-chevron {
      margin-top: 7px;
    }
  }
  .segments-select, .table-segments-clicked {
    @include button_large_text;
    position: relative;
    display: flex;
    flex-direction: row;
    font-weight: 600;

    @media (max-width: $tablet-width-large) {
      padding-top: 12px;
    }
  }
  .segments-select {
    cursor: pointer;
    .chevron {
      padding-top: 5px;
    }
  }
  .table-segments-clicked {
    .save, .clear, .saved {
      cursor: pointer;
      font-weight: 700;
    }
    .save, .saved {
      color: $main-blue;
    }
    .clear {
      color: $col-error;
      margin-left: 20px;
      @media (max-width: $mobile-width) {
        margin-left: 10px;
      }
    }
    .saved {
      cursor: default;
      opacity: 0.5;
    }
  }
}

.segment {
  top: 30px;
  margin-left: -40px;
  width: 240px;
  font-size: 14px;
  .segments {
    text-align: center;
    cursor: default;
    .p-spinner-wrap {
      min-height: 100px;
      display: flex;
      align-items: center;
    }
    .segments-info {
      padding: 16px;
      opacity: 0.5;
    }
    .cancel {
      cursor: pointer;
      color: $col-error;
      padding-bottom: 16px;
    }
    .segment-input-wrap {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 12px 5px;
      border-bottom: 1px solid $border-light;

      .save-segment {
        cursor: pointer;
        color: $main-blue;
      }
      .segment-input {
        @include h5;
        border: none;
        outline: none;
        overflow: auto;
        &::placeholder {
          color: $grey-medium;
          opacity: 1;
        }
      }
    }
    .empty-top {
      padding-top: 20px;
    }
    .segment-item {
      text-align: left;
      color: $main-blue;
      padding: 15px 15px 12px;
      border-bottom: 1px solid $border-light;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > div {
        cursor: pointer;
        &:not(:first-child) {
          padding-top: 2px;
          opacity: 0.5;
        }
      }
      .delete {
        height: 12px;
      }
    }
  }

  @media (max-width: $tablet-width-large) {
    top: 45px;
  }
}
</style>
